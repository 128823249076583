/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApexOptions } from "apexcharts";
import React from "react";
import ReactApexChart from "react-apexcharts";

interface GraphData {
  y: (string | number)[];
  x: (string | number)[];
}

interface StackedBarProps {
  graphdata: GraphData;
}

const StackedBar: React.FC<StackedBarProps> = ({ graphdata }) => {
  const { x, y } = graphdata;
  const filteredSeries: ApexAxisChartSeries = [{
    name: "Data Series 1",
    data: y.map((item) => {
      let num = Number(item);

      // normalizing the data for better visualization
      //(currently we have dummy values in db)
      while (num >= 99999) {
        num = num / 10;
      }
      return num;
    }),
  }];
  console.log(filteredSeries);
  const chartOptions: ApexOptions = {
    chart: {
      type: "bar",
      height: 100,
      stacked: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: "2px",
              fontWeight: 10,
            },
          },
        },
      },
    },
    stroke: {
      width: 1,
      colors: ["#000"],
    },
    title: {
      text: "",
    },
    xaxis: {
      categories: x,
    },
    yaxis: {
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 0,
    },
  };

  return (
    <div>
      <div id="chart" className="w-full">
        <ReactApexChart
          options={chartOptions}
          series={filteredSeries}
          type="bar"
          height={400}
        />
      </div>
    </div>
  );
};

export default StackedBar;
