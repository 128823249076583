import { FireAI_Logo_light, Sidebar_Close } from "@assets/index";
import { SidebarLayoverTypes } from "@interfaces/components/common/sidebar";
import { useAppSelector, useAppDispatch } from "@store/store";
import { toggleSideBar } from "@store/features/sidebar/SidebarSlice";
import { Link } from "@tanstack/react-router";
import { toggleContactUs } from "@store/features/topbar/contactus";
import ThemeToggleBtn from "./ThemeToggleBtn";
import { sidebarLogos } from "@data/common/sidebar";
import CustomDropdown from "./CustomeSIdebarDropdown";


const SidebarLayover: React.FC<{ props: SidebarLayoverTypes }> = ({ props }) => {
  const sidebarStatus = useAppSelector((state) => state.sidebar.expanded);
  const dispatch = useAppDispatch();
  const themeMode = useAppSelector((state) => state.theme.themeMode);

  const closeSidebar = () => {
    dispatch(toggleSideBar({ open: false }));
  };

  return (
    <div className={`${sidebarStatus ? "flex" : "hidden"} py-7 text-fireai-blue h-screen max-h-screen min-h-screen bg-[#EDEDED] dark:bg-[#1D1D1D] w-screen md:w-[200px] fixed z-30  flex-col justify-between`}>
      <div>
        <div className="flex flex-row justify-center md:justify-start">
          <Link to="/dashboard" className="w-full" onClick={closeSidebar}>
            <img
              alt="Fire AI Logo Full"
              src={FireAI_Logo_light}
              className={`w-fit md:w-32 mx-auto ${themeMode === "dark" ? "filter invert brightness-0" : ""}`}
            />
          </Link>
        </div>

        <div className="px-3 font-semibold text-[#3E3E3E] dark:text-[#DADADA] py-2 mt-3 mx-2 text-[15px] flex gap-2 items-center border-2 shadow-md rounded-xl">
          <img src={props.user?.photo} alt="Profile" className="rounded-md w-7" />
          <div>
            {props.user?.name}
            <div className="text-[0.5rem]">{props.user?.email}</div>
          </div>
        </div>

        <div className="w-full mt-3">
          {props.data.map((item, index) => (
            <div key={index}>
              {!item.subMenu ? (
                <Link
                  to={item.location}
                  onClick={closeSidebar}
                  className="text-[#3E3E3E] flex flex-row items-center h-5 font-medium text-base mx-2 rounded-md cursor-pointer py-4 dark:hover:text-[#1B41E9] dark:text-[#DADADA] hover:bg-blue-200"
                >
                  <span className="inline-flex mx-3 justify-center items-center">{sidebarLogos[item.icon]}</span>
                  {item.mainName}
                </Link>
              ) : (
                <CustomDropdown item={item} closeSidebar={closeSidebar} />
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="">
        <div className="mb-5 w-1/4 mx-6 relative top-6 -left-1">
          <ThemeToggleBtn />
        </div>
        <img
          src={Sidebar_Close}
          alt="close icon"
          className="w-6 relative bottom-[13%] z-10 -right-[83%]"
          onClick={closeSidebar}
        />
        <div className="flex flex-col gap-1 text-center">
          {props.bottomData.map((option, index) => (
            <Link
              to={option.location}
              key={index}
              className="text-[#3E3E3E] flex flex-row items-center h-5 font-medium text-base mx-2 rounded-md cursor-pointer py-4 dark:hover:text-[#1B41E9] dark:text-[#DADADA] hover:bg-blue-200"
              onClick={() => {
                if (option.mainName === "Product Demo") {
                  dispatch(toggleContactUs({ open: true }));
                }
                closeSidebar();
              }}
            >
              <span className="inline-flex mx-3 justify-center items-center">{sidebarLogos[option.icon]}</span>
              {option.mainName}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SidebarLayover;
