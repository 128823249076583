import { getAxiosClient } from '@functions/api/axiosInstance'
import { AxiosResponse } from 'axios'
import { authModesTypes } from "@interfaces/api/auth"
import ReactGA from 'react-ga4'


const FetchAuthModes = async (): Promise<authModesTypes[] | null> => {
    try {
        const _axios = await getAxiosClient()
        const AuthModesResponse: AxiosResponse<authModesTypes[]> = await _axios.get<authModesTypes[]>("/auth/modes")
        ReactGA.event({ category: "API Request", action: "Fetch Auth Modes Data", label: `Success in ${AuthModesResponse.headers['responseTime'] / 1000}s` });
        return AuthModesResponse.data
    } catch (error) {
        console.log("error while fetching Auth Modes Data", error);
        ReactGA.event({ category: "API Request", action: "Fetch sidebar data", label: "Failure" });
        return null;
    }
}

export { FetchAuthModes }