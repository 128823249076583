import { createFileRoute } from '@tanstack/react-router'
import { useAppDispatch, useAppSelector } from "@store/store"
import GenerateTable from '@components/dashboard/GenerateTable'
import SearchBar from '@components/dashboard/SearchBar'
import SuggestiveBoxes from '@components/dashboard/SuggestiveBoxes'
import QueryToolbar from '@components/dashboard/toolbar'
import Loader from '@components/common/Loader'
// import Insights from '@components/dashboard/Insights'
import FeedBack from '@components/dashboard/FeedBack'
import ReactGA from 'react-ga4'
import { useEffect } from 'react'
import { setAccessState } from "@store/features/audio/microphoneAcessSlice"

export const Route = createFileRoute('/dashboard')({
  component: Dashboard,
})

function Dashboard() {
  const dispatch = useAppDispatch()
  ReactGA.send({ hitType: "pageview", page: `${window.location.pathname + window.location.search}`, title: "Query page" });
  const tableData = useAppSelector((state) => state.queryResponse.value)
  const tableLoadingState = useAppSelector((state) => state.queryResponse.loadingState)
  useEffect(() => {
    const getMicAccessStatus = async () => {
      // @ts-expect-error Microphone permission is included in browser type space
      const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
      if (permissionStatus.state === 'granted') {
        dispatch(setAccessState({ access: true }))
      }
    }
    getMicAccessStatus()
  })

  return (
    <div className="p-2 mt-10 grid grid-cols-4 sm:grid-cols-12 max-h-full">
      <div className='w-full col-span-4 sm:col-span-8 sm:col-start-3 mx-auto'>
        <SearchBar />
      </div>

      {
        (!tableData || tableData == null) &&
        <div className='w-full col-span-4 sm:col-span-8 sm:col-start-3 mx-auto'>
          <SuggestiveBoxes />
        </div>
      }

      {
        tableLoadingState &&
        <div className='text-center col-span-4 sm:col-span-12'><Loader />
          <span className='text-gray-400'>Loading your response</span>
        </div>
      }
      {
        tableData &&
        <div className='w-full col-span-4 sm:col-span-10 sm:col-start-2 mx-auto px-[3%] py-[1.5%] my-4 query-page-component-bg rounded-xl'>
          {!tableData.query_generated?.includes("Sorry") && !tableData.query_generated?.includes("rephrase your question?") &&
            <div className="w-full col-span-4 sm:col-span-8 sm:col-start-3 mx-auto mb-[2%] dark:bg-[#000]">
              <QueryToolbar data={tableData.data} reportName={"Generated Report"} />
            </div>}
          <>
            <div className='w-full overscroll-x-scroll mt-2'>
              <GenerateTable response={tableData} />
            </div>
          </>
        </div>
      }
      {/* {
        tableData && !tableData.query_generated?.includes("Sorry") && !tableData.query_generated?.includes("rephrase your question?") &&
        <div className='w-full col-span-4 sm:col-span-10 sm:col-start-2 mx-auto px-[3%] py-[2%] my-4 query-page-component-bg rounded-xl'>
          <Insights sqlQuery={tableData.query_generated || ""} />
        </div>
      } */}

      {
        tableData && !tableData.query_generated?.includes("Sorry") && !tableData.query_generated?.includes("rephrase your question?") &&
        <FeedBack sqlQuery={tableData.query_generated || ""} />
      }

    </div>
  )
}