import { FetchDetailedReport } from "@api/reports/fetchDetails"
import { useEffect, useState } from 'react'
import { DetailedResponseType, OutletDataType } from '@interfaces/api/reports/detailedResponse'
import { useAppSelector } from '@store/store'
import Loader from '@components/common/Loader'
import Chart from '@components/reports/chart'
import DatePickerComponent from '@components/reports/DatePicker'
import OutletSelector from '@components/reports/OutletSelector'
import GenerateTable from '@components/dashboard/GenerateTable'
import { TableColumnType } from '@interfaces/api/dashboard/prompts'
import ReactGA from 'react-ga4'
import QueryToolbar from '@components/dashboard/toolbar'
import KPIpanel from '@components/reports/KPIcard'


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isValidOutlets = (outlets: any): outlets is OutletDataType[] => {
    // Check if outlets contain HTML tags or invalid data
    const regex = /<\/?[a-z][\s\S]*>/i;
    return Array.isArray(outlets) && !regex.test(outlets.toString());
}


const VendorReport: React.FC<{ reports: string, company: string }> = ({ reports, company }) => {
    ReactGA.send({ hitType: "pageview", page: `${window.location.pathname + window.location.search}`, title: `Reports page ${window.location.pathname.length > 0 ? "with search" : null}` });
    const dateFilter = useAppSelector((state) => state.dateFilter)
    const outletFilter = useAppSelector((state) => state.outletFilter.outletFilter)
    const [fetchedData, setFetchedData] = useState<DetailedResponseType | null>(null)
    const [tableData, setTableData] = useState<{ columns: TableColumnType[], data: { [key: string]: unknown }[] }>({ columns: [], data: [] })

    const [outlets, setOutlets] = useState<OutletDataType[] | null>(null)
    const [reportLoading, setReportLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            setFetchedData(null)
            const res = await FetchDetailedReport({
                toDate: dateFilter.toDate,
                fromDate: dateFilter.fromDate,
                outlet: outletFilter,
                orgName: company,
                reportName: reports,
            })
            const data = res?.tableData
            setFetchedData(res)
            if (data?.columns && data?.rows) {
                setTableData({ columns: data.columns, data: data.rows })
            }
            setOutlets(isValidOutlets(res?.outlets) ? res.outlets : null)
            setReportLoading(false)
        }
        fetchData()
    }, [company, dateFilter, outletFilter, reports])



    return fetchedData ? (
        <div className='mx-auto w-11/12 py-5 md:overflow-y-hidden grid overflow-y-hidden'>
            <div className='pb-5 pt-2 text-center md:text-left w-full'>
                <h1 className='text-3xl px-2 dark:text-fireai-blue'>{fetchedData?.orgName}</h1>
            </div>
            {!reportLoading && fetchedData && <div className='w-full text-left font-medium bg-fireai-grey dark:bg-[#1D1D1D] flex flex-col md:flex-row justify-normal md:justify-between py-2 rounded-xl'>
                <h3 className='text-xl px-5 font-medium dark:text-fireai-blue'>{fetchedData?.reportName} Report</h3>
                <div className='flex gap-5 items-center mx-5'>
                    <OutletSelector isLoading={!(fetchedData)} outletArray={outlets || []} />
                    <DatePickerComponent isLoading={false} />
                </div>
            </div>}
            {fetchedData != null ? (
                <>
                    <KPIpanel kpi={fetchedData.kpiData} />
                    {/* <div className='flex justify-around flex-wrap gap-x-5 my-5 text-center w-full items-center'>
              <div className='grid grid-cols-12 grid-rows-2 w-full gap-6'>
                {fetchedData.kpiData.map((item, index) => {
                  return (
                    <KPIcard
                      index={index}
                      key={index}
                      label={item.label}
                      value={item.value}
                      bgColor={kpiCardBgColor[colorIndex++ % 4]}
                    />
                  );
                })}
              </div>
            </div> */}
                    <div className='grid grid-cols-12 gap-3 max-w-screen grid-flow-dense'>
                        <div className={`col-span-12 ${fetchedData.graphData.length > 1 ? 'lg:col-span-7' : 'lg:col-span-12'} order-2 lg:order- border-2 border-[#CACACA] rounded-xl`}>
                            {tableData.columns.length > 0 ?
                                <>
                                    <div className='py-2 border-b-2 dark:bg-[#1d1d1d] rounded-t-lg flex flex-row justify-between'>
                                        <div className='flex flex-col justify-center'>
                                            <span className='ml-2 font-medium'>{fetchedData.reportName} Table</span>
                                        </div>
                                        <div className='flex flex-col justify-center'>
                                            <QueryToolbar data={tableData.data} reportName={fetchedData?.reportName} />
                                        </div>
                                    </div>
                                    <GenerateTable response={tableData} />
                                </>
                                : <div className='h-full flex flex-col justify-center'><Loader /></div>
                            }
                            {/* {tableData.columns.length > 0 ?
                  <GenerateTable response={tableData} />
                  : <div className='h-full flex flex-col justify-center'><Loader /></div>
                } */}
                        </div>
                        {fetchedData.graphData.length > 1 ? <div className='col-span-12 lg:col-span-5 order-1 lg:order-2 border-2 border-[#CACACA] rounded-xl'>
                            <div className='py-2 border-b-2'>
                                <p className='ml-2 font-medium'>{fetchedData.reportName} Graph</p>
                            </div>
                            <Chart data={fetchedData.graphData as never[]} type={fetchedData.graphType} />
                        </div> : null}
                    </div>
                </>
            ) : (
                <div className='h-96 flex flex-col justify-center'>
                    <Loader />
                    <p className='text-center'>Your report is loading</p>
                </div>
            )}
        </div>
    ) : <Loader />;
}

export default VendorReport;