/* eslint-disable @typescript-eslint/no-explicit-any */
import { createFileRoute } from "@tanstack/react-router";
import { FetchAuthModes } from "@api/auth/modes";
import { authModesTypes } from "@interfaces/api/auth";
import CobeItem from "@assets/login/globe";
import ReactGA from "react-ga4";
import { useAppDispatch } from "@store/store";
import { TypeAnimation } from "react-type-animation";
import { setMessage } from "@store/features/message/messageSlice";
import { useEffect, useState } from "react";
import { getAxiosClient } from "@functions/api/axiosInstance";
import { UserDetails } from "@interfaces/components/common/sidebar";

export const Route = createFileRoute("/")({
  component: () => {
    return <Index />;
  },
});

function Index() {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<authModesTypes[] | null>();
  useEffect(() => {
    const fetchData = async () => {
      const dataAuth: authModesTypes[] | null = await FetchAuthModes();
      setData(dataAuth);
      console.log(dataAuth);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const checkUserLoggedIn = async () => {
      try {
        const _axios = await getAxiosClient();
        const isUser = await _axios.get<UserDetails>("/api/user/details");
        if (isUser) {
          history.pushState({}, "", "/dashboard");
        }
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          history.pushState({}, "", "/");
          return false;
        } else if (error.response) {
          console.error("error ", error);
          history.pushState({}, "", "/session-expired");
        } else {
          console.error("Network error or other issue: ", error);
          history.pushState({}, "", "/session-expired");
        }
      }
    };

    checkUserLoggedIn();
  }, []);
  // setIsLoggedIn(false);
  ReactGA.send({
    hitType: "pageview",
    page: `${window.location.pathname + window.location.search}`,
    title: "Login page of FireAI",
  });

  return (
    <div className="w-screen h-screen grid grid-cols-1 md:grid-cols-3 bg-black justify-center md:overflow-hidden">
      <div className="col-span-2 hidden md:flex flex-col justify-start overflow-hidden">
        <div className="ml-30 pl-36 pt-36">
          <span className="mt-20 font-bold text-4xl text-white">
            After you use it, all you will say is <br />
            "FireAI is{" "}
            <TypeAnimation
              sequence={[
                "fast",
                1000,
                "secure",
                1000,
                "dependable",
                1000,
                "easy to use",
                1000,
              ]}
              wrapper="span"
              speed={10}
              repeat={Infinity}
            />
            ".
          </span>
        </div>
        <div>
          <CobeItem />
        </div>
      </div>
      <div className="col-span-1 flex flex-col justify-center">
        {/* Container for FireAI Logo */}
        <div className="text-center mx-auto bg-gray-300 rounded-md py-5 relative w-11/12 md:w-10/12">
          <span className="absolute font-bold text-gray-700 rounded-full bg-gray-300 shadow-lg flex items-center justify-center font-mono h-[80px] w-[80px] mt-[-60px] left-1/2 transform -translate-x-1/2">
            <img
              src="https://ik.imagekit.io/fireai/fireai_logo_mini.png?updatedAt=1721489537089"
              alt="FireAI Logo"
              className="w-5/12"
            />
          </span>
          <h1 className="text-lg font-medium text-gray-700 pt-8">
            Get started with FireAI
          </h1>
          <div id="SocialLogins" className="text-center">
            {data
              ? data.map((mode: authModesTypes, index: number) => {
                  if (
                    mode.strategy !== "Sign In" &&
                    mode.strategy !== "Sign Up"
                  ) {
                    return (
                      <div
                        key={index}
                        className="w-10/12 my-3 rounded-md  mx-auto hover:text-gray-300 hover:bg-black border border-black hover:border-fireai-blue shadow-sm hover:shadow-xl cursor-pointer"
                      >
                        <span
                          className="rounded-full flex flex-row justify-center gap-1 p-3 mx-auto"
                          onClick={() =>
                            mode.disabled
                              ? dispatch(
                                  setMessage({
                                    type: "error",
                                    content: mode.disabledMessage!,
                                    duration: 5,
                                  })
                                )
                              : window.location.assign(
                                  `${mode.strategyLoginLink}`
                                )
                          }
                        >
                          <img
                            src={mode.strategyImage}
                            className="ml-5"
                            alt={mode.strategy}
                            height={25}
                            width={25}
                          />
                          <span className="ml-3 font-medium my-auto">
                            Login with {mode.strategy}
                          </span>
                        </span>
                      </div>
                    );
                  }
                })
              : null}
          </div>
        </div>
        <div className="mt-7"></div>
      </div>
    </div>
  );
}
export default Index;
