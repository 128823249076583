"use client"

import React, { useState, useEffect } from "react";
import ApexCharts from "react-apexcharts";
import { cx } from "../../lib/utils";
import { ApexOptions } from "apexcharts";

const DonutChart = ({
  data = [],
  category,
  value,
  colors = ["#FF4560", "#00E396", "#FEB019", "#008FFB", "#775DD0"], // Hardcoded colors array
  valueFormatter = (value) => value.toString(),
  onValueChange,
  className,
  ...other
}) => {
  const chartData = data.map((dataPoint) => dataPoint[value]);
  const chartLabels = data.map((dataPoint) => dataPoint[category]);

  // Determine the initial theme based on the user's preference
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const handleThemeChange = () => {
      setDarkMode(window.matchMedia("(prefers-color-scheme: light)").matches);
    };

    handleThemeChange(); // Set initial theme based on the current preference

    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", handleThemeChange);

    return () => {
      window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", handleThemeChange);
    };
  }, []);

  const chartOptions: ApexOptions = {
    series: chartData,
    chart: {
      type: "pie", // Changed to "pie"
      width: "100%",
      events: {
        dataPointSelection: (event, chartContext, config) => {
          if (onValueChange) {
            const selectedData = data[config.dataPointIndex];
            onValueChange({
              eventType: "sector",
              categoryClicked: selectedData[category],
              ...(selectedData as object),
            });
          }
        },
      },
    },
    labels: chartLabels,
    colors, // Using the hardcoded colors array
    legend: {
      position: "bottom",
    },
    theme: {
      mode: darkMode ? "dark" : "light",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const containerStyles = {
    backgroundColor: "transparent",
  };

  return (
    <div className={cx("h-full w-full", className)} style={containerStyles} {...other}>
      <ApexCharts options={chartOptions} series={chartData} type="pie" />
    </div>
  );
};

DonutChart.displayName = "DonutChart";

export { DonutChart };
